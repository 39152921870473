import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import MessageDialog from "../../PersonalizedMessages/MessageDialog";
import { useSnackbar } from "notistack";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useLocation } from "react-router-dom";
const ProfileImage = ({ full_name, image_url }) => {
  return (
    <div className="profile-image text-danger">
      <img
        style={{ borderRadius: "50%" }}
        onError={(e) => {
          e.currentTarget.src = "/assets/images/new_icons/ProfilePic.png";
        }}
        src={image_url || "/assets/images/new_icons/ProfilePic.png"}
        alt={`Profile of ${full_name}`}
      />
    </div>
  );
};

const ProfileDescription = ({ full_name, job, location, company_name }) => {
  return (
    <div className="profile-description">
      <h4 className="text-capitalize">{full_name}</h4>
      {job && <small className="d-block mb-1 text-capitalize"> {job}</small>}
      {company_name && (
        <small className="d-block mb-1 text-capitalize"> {company_name}</small>
      )}
      {location && (
        <small className="d-block text-capitalize">{`${location?.slice(
          0,
          100
        )} ...`}</small>
      )}
    </div>
  );
};

const ProfileLinkedIn = ({ details_url, handleLinkedin }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  console.log("currentPath: " + currentPath);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLink = (link) => {
    handleClose();
    window.open(link, "_blank");
  };
  if (!currentPath.includes("RealtimePeopleSearch")) {
    console.log("RealtimePeopleSearch");
    return (
      <div className="profile-linkedin">
        {details_url && (
          <>
            <Tooltip title="Tap for more actions" arrow>
              <Button
                data-cy="linkedin-link"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img
                  style={{ width: "80%", height: "80%" }}
                  src="/assets/images/linkedin1.png"
                  alt="LinkedIn Logo"
                />
              </Button>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                borderRadius: "50px",
              }}
            >
              <MenuItem
                role="link"
                href={details_url}
                target="_blank"
                onClick={() => handleLink(details_url)}
                sx={{
                  display: "flex",
                  gap: "4px",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                <PersonOutlineOutlinedIcon style={{ color: "#7B7A80" }} />
                Open Linkedin Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleLinkedin();
                }}
                sx={{
                  display: "flex",
                  gap: "4px",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                <EmailOutlinedIcon style={{ color: "#7B7A80" }} />
                Send Personalized Message
              </MenuItem>
              <MenuItem
                sx={{
                  display: "flex",
                  gap: "4px",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
                onClick={async () => {
                  handleClose();
                  await navigator.clipboard.writeText(details_url);
                  enqueueSnackbar("Linkedin profile Copied!");
                }}
              >
                <ContentCopyOutlinedIcon style={{ color: "#7B7A80" }} />
                Copy Linkedin Link
              </MenuItem>
            </Menu>
          </>
        )}
      </div>
    );
  }
  return (
    <div className="profile-linkedin">
      {details_url && (
        <>
          <Tooltip title="Tap for more actions" arrow>
            <Button
              data-cy="linkedin-link"
              href={details_url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ width: "80%", height: "80%" }}
                src="/assets/images/linkedin1.png"
                alt="LinkedIn Logo"
              />
            </Button>
          </Tooltip>
        </>
      )}
    </div>
  );
};

const Profile = ({ data, flexBasis, sheetName, savedList }) => {
  const {
    full_name,
    job,
    location,
    category,
    details_url,
    image_url,
    company_name,
  } = data;

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleLinkedin = () => {
    setMessage("linkedin");
    setOpen(true);
  };
  return (
    <div style={{ flexBasis: flexBasis }}>
      <div className="profile-container">
        <ProfileImage full_name={full_name} image_url={image_url} />
        <ProfileDescription
          full_name={full_name}
          job={job}
          location={location?.replace("Contact Info","")}
          category={category}
          company_name={company_name}
        />

        {sheetName ===
        "realtime-financial_intermediaries" ? null : !details_url &&
          savedList ? (
          <div
            style={{
              fontWeight: 500,
              flexBasis: "10%",
              letterSpacing: "0.03em",
            }}
          >
            NOT AVAILABLE
          </div>
        ) : (
          <ProfileLinkedIn
            details_url={details_url}
            handleLinkedin={handleLinkedin}
          />
        )}
      </div>
      <MessageDialog
        title={message}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default Profile;
