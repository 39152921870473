import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Link, NavLink, useHistory } from "react-router-dom";
import Modal from "./Modal";
import Cookies from "js-cookie";
import { AppContext } from "../../Context";
import { useDispatch } from "react-redux";
import { saveMapBoxAutocomplete } from "../../_reducer/areaDetail";
import {
  saveCategorySelected,
  saveCategorySelectedArray,
  saveClearSearchResult,
} from "../../_reducer/shared";
import { saveLeadsData, saveLeadsForMapMarker } from "../../_reducer/leads";
import Emitter from "../Utils/emitter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { doJwtLogout } from "../SignUp/auth";
import { apiServer } from "../../config";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import * as amplitude from "@amplitude/analytics-browser";
import IntegrationModal from "../Utils/IntegrationModal";
const NavBar = () => {
  const [user, setUser] = useState();
  const [parent, setParent] = useState(null);
  const [credits, setCredits] = useState([]);
  const [currentPlans, setCurrentPlans] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [platformCredits, setPlatformCredits] = useState([]);
  const [extensionCredits, setExtensionCredits] = useState([]);
  const [outreachCredits, setOutreachCredits] = useState([]);
  const [platformPlan, setPlatformPlan] = useState([{}]);
  const [extensionPlan, setExtensionPlan] = useState([{}]);
  const [outreachPlan, setOutreachPlan] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [popUp, setPopUp] = useState(localStorage.getItem("popup"));
  const { pathname } = useLocation();
  const { microIntraction, dispatch } = useContext(AppContext);
  const dispatchRe = useDispatch();
  const history = useHistory();

  const homeIcon = useRef(null);
  const listIcon = useRef(null);
  const historyIcon = useRef(null);
  const trendingIcon = useRef(null);

  const handleClose = (value) => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      await getUser();
      await getCredits();
      await getPlans();
    })();

    // Use this emitter where required to re-render Navbar to show updated credits
    Emitter.on("updateCredits", getUser);
    Emitter.on("fetchCredits", getCredits);
    return () => {
      Emitter.off("updateCredits", getUser);
      Emitter.off("fetchCredits", getCredits);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const plaCredits = credits?.filter((c) => c.product_name === "platform");
  const totalCredits =
    plaCredits[0]?.credit_type_1 + plaCredits[0]?.credit_type_2;

  const getParentEmail = async (parent) => {
    try {
      console.log("in getparent user: ", user);
      const user_res = await fetch(apiServer + "/user/search_user_by_id", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id: parent }),
      });

      async function handleUserSuccess(user_res) {
        const response = await user_res?.json();
        console.log("parent call in success status....", response);
        setParent(response?.email);
      }

      switch (user_res.status) {
        case 200:
          return await handleUserSuccess(user_res);
        case 401:
          return handleUnAuthorized(user_res);
        default:
          return handleError(user_res);
      }
    } catch (err) {
      handleError(err);
    }
  };
  const getPlans = async () => {
    // const planResponse = await fetch(
    //   `${apiServer}/payment/razorpay/plans/extension`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //       Authorization: `Bearer ${Cookies.get("user_token")}`,
    //     },
    //   }
    // );
    const res = await fetch(`${apiServer}/payment/stripe/subscription`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get("user_token")}`,
      },
    });
    const result = await res.json();
    console.log("get plan result", result);
    const platformSubs = result?.filter(
      (item) => item?.plan_type === "platform"
    );
    const extensionSubs = result?.filter(
      (item) => item?.plan_type === "extension"
    );
    const outreachSubs = result?.filter(
      (item) => item?.plan_type === "outreach"
    );
    setCurrentPlans(platformSubs);
    // const jsonRes = await planResponse.json();
    // console.log("plans Json: ", jsonRes);

    setPlatformPlan(platformSubs ? platformSubs : []);
    setExtensionPlan(extensionSubs ? extensionSubs : []);
    setOutreachPlan(outreachSubs ? outreachSubs : []);
  };

  function handleError(status) {
    console.error(`Got HTTP Error ${status}`);
  }

  function handleUnAuthorized() {
    setPopupVisible(true);
    console.log("User is UnAuthorized in NAVBAR");
  }

  const getUser = async () => {
    try {
      const user_res = await fetch(apiServer + "/users/me", {
        headers: {
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
      });

      async function handleUserSuccess(user_res) {
        const response = await user_res?.json();
        console.log("user in success status....", response);
        setUser({ ...response });
        if (response.parent) await getParentEmail(response?.parent);
      }

      switch (user_res.status) {
        case 200:
          return await handleUserSuccess(user_res);
        case 401:
          return handleUnAuthorized(user_res);
        default:
          return handleError(user_res);
      }
    } catch (err) {
      handleError(err);
    }
  };
  const getCredits = async () => {
    try {
      const credit_res = await fetch(apiServer + "/credits/", {
        headers: {
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
      });

      async function handleUserSuccess(user_res) {
        const response = await user_res.json();
        console.log("in success status....", response);
        const platformCredits = response?.filter(
          (res) => res.product_name === "platform"
        );
        const extensionCredits = response?.filter(
          (res) => res.product_name === "extension"
        );
        const outreachCreditsData = response?.filter(
          (res) => res?.product_name === "outreach"
        );
        setPlatformCredits(platformCredits);
        setExtensionCredits(extensionCredits);
        setOutreachCredits(outreachCreditsData);
        console.log("outreachCredits", outreachCreditsData);
        setCredits(response);
      }

      switch (credit_res?.status) {
        case 200:
          return await handleUserSuccess(credit_res);
        case 401:
          return handleUnAuthorized(credit_res);
        default:
          return handleError(credit_res);
      }
    } catch (err) {
      handleError(err);
    }
  };
  const handleEmptyRedux = () => {
    dispatchRe(saveMapBoxAutocomplete({}));
    dispatchRe(saveCategorySelected(null));
    dispatchRe(saveCategorySelectedArray([]));
    dispatchRe(saveClearSearchResult(true));
    dispatchRe(saveLeadsData({}));
    dispatchRe(saveLeadsForMapMarker([]));
  };

  const handleLogout = async () => {
    if (window.navigator.onLine) {
      await doJwtLogout();
    }
    Cookies.remove("user_token");
    Cookies.remove("user_email");
    Cookies.remove("user_id");
    sessionStorage.clear();
    localStorage.clear();
    console.log("document.cookie()...", document.cookie);
    window.BrevoConversations("kill");
    window.BrevoConversationsSetup = { visitorId: null };
    handleEmptyRedux();

    history.push({
      pathname: "/login",
      state: { loggedOut: true },
    });
  };

  const addIntraction = (applyTo) => {
    if (applyTo === "home") {
      const homeEle = homeIcon.current;
      homeEle.classList.add("home-intraction");
    } else if (applyTo === "list") {
      const listEle = listIcon.current;
      listEle.classList.add("home-intraction");
    } else if (applyTo === "history") {
      const history = historyIcon.current;
      history.classList.add("home-intraction");
    } else if (applyTo === "trending") {
      const trending = trendingIcon.current;
      trending.classList.add("home-intraction");
    }
  };

  useEffect(() => {
    if (microIntraction.name === "homeIntraction") {
      addIntraction("home");
    } else if (microIntraction.name === "listIntraction") {
      addIntraction("list");
    } else if (microIntraction.name === "historyIntraction") {
      addIntraction("history");
    } else if (microIntraction.name === "trendingIntraction") {
      addIntraction("trending");
    }
  });

  useEffect(() => {
    if (
      plaCredits[0]?.credit_type_1 + plaCredits[0]?.credit_type_2 < 5 &&
      popUp === null
    ) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plaCredits]);

  const handleClosePopUp = () => {
    localStorage.setItem("popup", "close");
    setPopUp("close");
    setOpen(false);
  };

  const handleOpenModal = () => setOpenModal(true);

  return (
    <>
      {popupVisible ? <Modal /> : null}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" component="div" color="#FB3E3E">
            Running low on credits?
          </Typography>
          <CancelIcon
            sx={{ color: "#fb3e3e", cursor: "pointer", fontSize: "2rem" }}
            onClick={handleClosePopUp}
          />
        </DialogTitle>
        <Card sx={{ minWidth: 275, padding: "20px 30px" }}>
          <CardContent>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Keep prospecting by purchasing more for continued growth
            </Typography>
          </CardContent>
          <CardActions>
            <Link
              to="/payment/platform"
              onClick={handleClosePopUp}
              className="nav-buy-link"
            >
              Buy now
            </Link>
            <Button
              className="nav-later-link"
              sx={{ cursor: "pointer" }}
              onClick={handleClosePopUp}
            >
              May be later
            </Button>
          </CardActions>
        </Card>
      </Dialog>
      <div style={{ paddingRight: "0px" }}>
        <nav
          className="header-navbar navbar navbar-expand-xl bg-light "
          style={{ paddingRight: "0px" }}
        >
          <div className="container-fluid">
            <ul className="navbar-nav-profile navbar-nav align-items-center ms-auto">
              <li className="nav-item me-md-4 me-3 navHover">
                <NavLink
                  exact
                  activeClassName="active-class"
                  className="navHover"
                  to="/dashboard"
                  onClick={() => {
                    dispatch({
                      type: "setMicrointraction",
                      payload: { name: "homeIntraction", active: "true" },
                    });
                    amplitude.logEvent("Dashboard Button Clicked", {
                      role: "user",
                    });
                  }}
                >
                  <img
                    className="blue nav-icon home-icon navHover"
                    src="/assets/images/new_icons/home.png"
                    alt="home here"
                    ref={homeIcon}
                  />
                  <span className="ps-2" id="home">
                    Home
                  </span>
                </NavLink>
              </li>

              <li className="nav-item me-md-4 me-3 d-flex flex-column align-items-center justify-content-center">
                <NavLink
                  exact
                  className={`navHover ${
                    pathname.includes("/savedLists") ? "active-class" : ""
                  }`}
                  to="/savedLists"
                  onClick={() => {
                    dispatch({
                      type: "setMicrointraction",
                      payload: { name: "listIntraction", active: "true" },
                    });
                    amplitude.logEvent("SavedList Button Clicked", {
                      role: "user",
                    });
                  }}
                >
                  <img
                    className="nav-icon navHover"
                    src="/assets/images/new_icons/Saved_List.png"
                    alt="saved here"
                    ref={listIcon}
                  />
                  <span className="ps-2">Saved lists</span>
                </NavLink>
              </li>

              <li className="nav-item me-md-4 me-3">
                <NavLink
                  data-cy="nav-history-link"
                  exact
                  className="navHover"
                  activeClassName="active-class"
                  to="/history"
                  onClick={() => {
                    dispatch({
                      type: "setMicrointraction",
                      payload: { name: "historyIntraction", active: "true" },
                    });
                    amplitude.logEvent("History Button Clicked", {
                      role: "user",
                    });
                  }}
                >
                  <img
                    className="nav-icon navHover"
                    src="/assets/images/new_icons/History.png"
                    alt="history here"
                    ref={historyIcon}
                  />
                  <span className="ps-2">History</span>
                </NavLink>
              </li>

              <li className="nav-item me-md-4 me-3 navHover">
                <NavLink
                  className="navHover"
                  exact
                  activeClassName=" active-class"
                  to="/realTimePage"
                  onClick={() => {
                    dispatch({
                      type: "setMicrointraction",
                      payload: { name: "trendingIntraction", active: "true" },
                    });
                    amplitude.logEvent("RealTimePage Button Clicked", {
                      role: "user",
                    });
                  }}
                >
                  <img
                    className="nav-icon navHover"
                    src="/assets/images/new_icons/Trending_leads.png"
                    alt="tranding here"
                    ref={trendingIcon}
                  />
                  <span className="ps-2">Trending Leads</span>
                </NavLink>
              </li>

              {/*Geolocator*/}

              <li className="nav-item me-md-4 me-3 navHover">
                <NavLink
                  className="navHover"
                  exact
                  activeClassName=" active-class"
                  to="/geolocator"
                  onClick={() => {
                    dispatch({
                      type: "setMicrointraction",
                      payload: { name: "geolocatorIntraction", active: "true" },
                    });
                    amplitude.logEvent("GeoLocator Button Clicked", {
                      role: "user",
                    });
                  }}
                >
                  <img
                    className="nav-icon navHover"
                    src="/assets/images/accord-map-pin.png"
                    alt="tranding here"
                    ref={trendingIcon}
                  />
                  <span className="ps-2">GeoLocator</span>
                </NavLink>
              </li>
              {/* FIXME: When Engage module is ready for production */}
              {/*<li className="nav-item me-md-4 me-3 navHover">*/}
              {/*  <NavLink*/}
              {/*    className="navHover"*/}
              {/*    exact*/}
              {/*    activeClassName=" active-class"*/}
              {/*    to="/engage"*/}
              {/*  >*/}
              {/*    <img*/}
              {/*      className="nav-icon navHover"*/}
              {/*      src="/assets/images/engage.svg"*/}
              {/*      alt="engage here"*/}
              {/*      ref={trendingIcon}*/}
              {/*    />*/}
              {/*    <span className="ps-2">Engage</span>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}

              <li className="nav-item me-md-4 me-3">
                <div className="nav-item dropdown">
                  <span
                    className="btn btn-outline-danger nav-link credit-btn"
                    data-cy="credits-left"
                  >
                    {totalCredits || 0} Credits Left
                  </span>
                  <ul className="dropdown-menu">
                    <li>
                      <p className="dropdown-item">
                        <span
                          className="refresh-credits-container"
                          role="button"
                          onClick={() => getCredits()}
                        >
                          <span>Refresh credits</span>
                          <FontAwesomeIcon
                            icon={faRefresh}
                            className="refresh-button"
                          />
                        </span>
                      </p>
                    </li>
                    {/* Platform Credits */}
                    <li>
                      <p
                        className="dropdown-item d-flex"
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/assets/images/pro-codesandbox.png"
                          alt="title"
                        />
                        My {platformCredits[0]?.product_name} credits:
                        <span
                          className="i-tool pl-1"
                          tooltip-data={`1 profile credit will be deducted every time you unlock a verified phone number.\n\n 1 mail credit will be deducted every time you unlock a verified email id or work email id.`}
                        >
                          <img
                            src="/assets/images/i_icon.png"
                            alt="#"
                            className="i-icon"
                          />
                        </span>
                      </p>
                    </li>
                    <li>
                      <div className="dropdown-progress">
                        <p className="small">
                          Profile credits remaining:{" "}
                          {platformCredits[0]?.credit_type_1 >
                          platformPlan[0]?.credit_type_1 ? (
                            <p>
                              {platformCredits[0]?.credit_type_1 -
                                platformPlan[0]?.credit_type_1}
                              {" + "}
                              {platformPlan[0]?.credit_type_1}
                            </p>
                          ) : (
                            platformCredits[0]?.credit_type_1
                          )}{" "}
                          / {platformPlan[0]?.credit_type_1 || 5}
                        </p>
                        <div className="progress mb-2">
                          <div
                            className="progress-bar"
                            style={{
                              width: user
                                ? (platformCredits[0]?.credit_type_1 /
                                    (platformPlan[0]?.credit_type_1 || 5)) *
                                    100 +
                                  "%"
                                : "",
                            }}
                            role="progressbar"
                            aria-valuenow={"45"}
                            aria-valuemin="0"
                            aria-valuemax={"100"}
                          />
                        </div>
                      </div>
                      <div className="dropdown-progress">
                        <p className="small">
                          Email credits remaining:{" "}
                          {platformCredits[0]?.credit_type_2 >
                          platformPlan[0]?.credit_type_2 ? (
                            <p>
                              {platformCredits[0]?.credit_type_2 -
                                platformPlan[0]?.credit_type_2}
                              {" + "}
                              {platformPlan[0]?.credit_type_2}
                            </p>
                          ) : (
                            platformCredits[0]?.credit_type_2
                          )}{" "}
                          / {platformPlan[0]?.credit_type_2 || 5}
                        </p>
                        <div className="progress mb-2">
                          <div
                            className="progress-bar"
                            style={{
                              width: user
                                ? (platformCredits[0]?.credit_type_2 /
                                    (platformPlan[0]?.credit_type_2 || 5)) *
                                    100 +
                                  "%"
                                : "",
                            }}
                            role="progressbar"
                            aria-valuenow={"45"}
                            aria-valuemin="0"
                            aria-valuemax={"100"}
                          />
                        </div>
                      </div>
                    </li>
                    <hr className="mb-2" />
                    {/* Extension Credits */}
                    <li>
                      <p
                        className="dropdown-item d-flex"
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/assets/images/pro-codesandbox.png"
                          alt="title"
                        />
                        My {extensionCredits[0]?.product_name} credits:
                        <span
                          className="i-tool pl-1"
                          tooltip-data={`1 profile credit will be deducted every time you unlock a verified phone number.\n\n 1 mail credit will be deducted every time you unlock a verified email id or work email id.`}
                        >
                          <img
                            src="/assets/images/i_icon.png"
                            alt="#"
                            className="i-icon"
                          />
                        </span>
                      </p>
                    </li>
                    <li>
                      <div className="dropdown-progress">
                        <p className="small">
                          Profile credits remaining:{" "}
                          {extensionCredits[0]?.credit_type_1 >
                          platformPlan[0]?.credit_type_1 ? (
                            <p>
                              {extensionCredits[0]?.credit_type_1 -
                                platformPlan[0]?.credit_type_1}
                              {" + "} {platformPlan[0]?.credit_type_1}
                            </p>
                          ) : (
                            extensionCredits[0]?.credit_type_1
                          )}{" "}
                          /{" "}
                          {user?.signup_source === "platform"
                            ? platformPlan[0]?.credit_type_1 || 5
                            : extensionPlan[0]?.credit_type_1 || 5}
                        </p>
                        <div className="progress mb-2">
                          <div
                            className="progress-bar"
                            style={{
                              width: user
                                ? user?.signup_source === "platform"
                                  ? (extensionCredits[0]?.credit_type_1 /
                                      (platformPlan[0]?.credit_type_1 || 5)) *
                                      100 +
                                    "%"
                                  : (extensionCredits[0]?.credit_type_1 /
                                      (extensionPlan[0]?.credit_type_1 || 5)) *
                                      100 +
                                    "%"
                                : "",
                            }}
                            role="progressbar"
                            aria-valuenow={"45"}
                            aria-valuemin="0"
                            aria-valuemax={"100"}
                          />
                        </div>
                      </div>
                      <div className="dropdown-progress">
                        <p className="small">
                          Email credits remaining:{" "}
                          {extensionCredits[0]?.credit_type_2 >
                          platformPlan[0]?.credit_type_2 ? (
                            <p>
                              {" "}
                              {extensionCredits[0]?.credit_type_2 -
                                platformPlan[0]?.credit_type_2}
                              {" + "}
                              {platformPlan[0]?.credit_type_2}
                            </p>
                          ) : (
                            extensionCredits[0]?.credit_type_2
                          )}{" "}
                          /{" "}
                          {user?.signup_source === "platform"
                            ? platformPlan[0]?.credit_type_2 || 5
                            : extensionPlan[0]?.credit_type_2 || 5}
                        </p>
                        <div className="progress mb-2">
                          <div
                            className="progress-bar"
                            style={{
                              width: user
                                ? user?.signup_source === "platform"
                                  ? (extensionCredits[0]?.credit_type_2 /
                                      (platformPlan[0]?.credit_type_2 || 5)) *
                                      100 +
                                    "%"
                                  : (extensionCredits[0]?.credit_type_2 /
                                      (extensionPlan[0]?.credit_type_2 || 5)) *
                                      100 +
                                    "%"
                                : "",
                            }}
                            role="progressbar"
                            aria-valuenow={"45"}
                            aria-valuemin="0"
                            aria-valuemax={"100"}
                          />
                        </div>
                      </div>
                    </li>
                    <hr className="mb-2" />
                    {/* Outreach Credits */}
                    {/* <li>
                      <p
                        className="dropdown-item d-flex"
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/assets/images/pro-codesandbox.png"
                          alt="title"
                        />
                        My {outreachCredits[0]?.product_name} credits:
                        <span
                          className="i-tool pl-1"
                          tooltip-data={`1 credit is deducted for successful connection request sent. If already connected/sent , 1 credit will be deducted on first message. No deduction in any other case.`}
                        >
                          <img
                            src="/assets/images/i_icon.png"
                            alt="#"
                            className="i-icon"
                          />
                        </span>
                      </p>
                    </li> */}
                    {/* <li>
                      <div className="dropdown-progress">
                        <p className="small">
                          Profile credits remaining:{" "}
                          {outreachCredits[0]?.credit_type_1 >
                          outreachPlan[0]?.credit_type_1 ? (
                            <p>
                              {outreachCredits[0]?.credit_type_1 -
                                outreachPlan[0]?.credit_type_1}
                              {" + "} {outreachPlan[0]?.credit_type_1}
                            </p>
                          ) : (
                            outreachCredits[0]?.credit_type_1
                          )}{" "}
                          / {outreachPlan[0]?.credit_type_1 || 100}
                        </p>
                        <div className="progress mb-2">
                          <div
                            className="progress-bar"
                            style={{
                              width: user
                                ? (outreachCredits[0]?.credit_type_1 /
                                    (outreachPlan[0]?.credit_type_1 || 100)) *
                                    100 +
                                  "%"
                                : "",
                            }}
                            role="progressbar"
                            aria-valuenow={"45"}
                            aria-valuemin="0"
                            aria-valuemax={"100"}
                          />
                        </div>
                      </div>
                    </li>
                    <hr className="mb-2" /> */}
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <div className="nav-item dropdown">
                  <a
                    className="profile-avata nav-link"
                    data-bs-toggle="dropdown"
                    href="#"
                  >
                    <img
                      src="/assets/images/new_icons/ProfilePic.png"
                      alt="search here"
                    />
                  </a>
                  <ul className="dropdown-menu">
                    <li
                    // role="button"
                    // onClick={() => {
                    //   history.push({
                    //     pathname: "/profile",
                    //   });
                    // }}
                    >
                      <h5>
                        <span className="fw-bolder">{user?.username}</span>
                      </h5>
                      {user?.email}
                    </li>

                    {user?.parent ? (
                      <li>
                        <br />
                        <h5>
                          <span className="fw-bolder">Parent Email ID</span>
                        </h5>
                        {parent}
                      </li>
                    ) : null}

                    <hr />

                    <div className="my-1">
                      <h3
                        style={{
                          color: "#fb3e3e",
                          marginBottom: "15px",
                        }}
                      >
                        Current Plan
                      </h3>
                      {currentPlans?.length > 0 ? (
                        <>
                          {currentPlans?.map((currentPlan) => {
                            return (
                              <>
                                <h6>{currentPlan?.plan_name}</h6>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <h6
                                    style={{
                                      borderRight: "1px solid #ddd",
                                      padding: "0 10px 0px 0px ",
                                    }}
                                  >
                                    {currentPlan?.currency === "inr"
                                      ? "₹"
                                      : currentPlan?.currency === "usd"
                                      ? "$"
                                      : "£"}{" "}
                                    {currentPlan?.amount}
                                  </h6>
                                  <h6
                                    style={{
                                      borderRight: "1px solid #ddd",
                                      padding: "0 10px 0px 0px",
                                    }}
                                  >
                                    {currentPlan?.period}
                                  </h6>
                                  <p>{currentPlan?.status}</p>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <h6>You are on free plan</h6>
                          {user?.children ? (
                            <button
                              className="btn btn-danger"
                              style={{
                                borderRadius: 0,
                                outline: "none",
                              }}
                              onClick={() =>
                                history.push({
                                  pathname: "/payment/platform",
                                })
                              }
                            >
                              Buy one
                            </button>
                          ) : null}
                        </>
                      )}
                    </div>
                    <hr />

                    <li>
                      <a
                        className="dropdown-item"
                        href={process.env.REACT_APP_CONFIG_PRODUCTION__API_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        API Docs
                      </a>
                    </li>
                    {(!user?.children && !user?.parent) || user?.children ? (
                      <li>
                        <a className="dropdown-item" href="/payment/platform">
                          Buy Credits
                        </a>
                      </li>
                    ) : null}

                    <li>
                      <a
                        className="dropdown-item"
                        href="/creditLog"
                        // style={{
                        // pointerEvents: "none",
                        // }}
                      >
                        Credit Transactions
                      </a>
                    </li>
                    {/* TODO: Create Profile settings with proper usage, currently it does not offer anything */}
                    <li>
                      <a className="dropdown-item" href="/profile">
                        Profile Settings
                      </a>
                    </li>
                    <li>
                      <div
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={handleOpenModal}
                      >
                        Integrations
                      </div>
                      <IntegrationModal
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                      />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={
                          process.env.REACT_APP_CONFIG_EXTENSION_INSTALL_URL
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        Install Chrome Extension
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/FAQ">
                        FAQs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={`${process.env.REACT_APP_CONFIG_PRODUCTION_URL}dashboard?lou=492995510751`}
                      >
                        How To Use
                      </a>
                    </li>
                    <li>
                      <div onClick={async (event) => await handleLogout(event)}>
                        <Link to="/login" className="dropdown-item">
                          <span className="text-muted me-3">Logout</span>{" "}
                          <img
                            src="/assets/images/new_icons/Log Out.png"
                            alt="log out button"
                            style={{ marginBottom: "2px" }}
                          />
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};
export default NavBar;
