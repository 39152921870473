import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { Button, ListGroup } from "react-bootstrap";
import { Map } from "react-lodash";
import { fetchSMEData, fetchSMEFilteredRecords } from "./SmeQueries";
import { FilterLabelInternal } from "../../SharedComponent/Prospecting/ProspectFilterLabel";
import { isObjectValuesEmpty, Spinner } from "../../Utils/utils";

const collection = [
  "Upto Rs. 50 Lakh",
  "More than Rs. 500 Crore",
  "Rs. 50 Lakh - 1 Crore",
  "Rs. 1 - 2 Crore",
  "Rs. 2 - 5 Crore",
  "Rs. 5 - 10 Crore",
  "Rs. 10 - 25 Crore",
  "Rs. 25 - 50 Crore",
  "Rs. 50 - 100 Crore",
  "Rs. 100 - 500 Crore",
  "Rs. 500 - 1000 Crore",
  "Rs. 1000 - 5000 Crore",
];

/**
 * Generates list of options for a given dropdown
 *
 * @param collection - Pass array containing options for the dropdown
 * @param handleInput - Pass the function the way you want to handle the input
 * @return {JSX.Element}
 * @constructor
 */
const FilterDropdown = ({ collection, handleInput }) => {
  return (
    <ListGroup data-cy="revenue-list">
      <Map
        collection={collection}
        iteratee={(x) => {
          return (
            <ListGroup.Item>
              <div className="d-grid gap-0" data-cy="revenue">
                <Button
                  variant="outline-dark"
                  onClick={(e) => handleInput(e, x)}
                >
                  {x}
                </Button>
              </div>
            </ListGroup.Item>
          );
        }}
      />
    </ListGroup>
  );
};
const isValidGstNo = (str) => {
  let regex = new RegExp(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  );

  if (str == null) {
    return "false";
  }
  if (regex.test(str) == true) {
    return "true";
  } else {
    return "false";
  }
};
/**
 * To generate more filters on SME Page, Add more of <FilterInput /> into SME Filters Component
 * @param id - defines prePayload key, value pair
 * @param name - Filter Name
 * @param value - placeholder value dynamically able to change
 * @param handleInput - updates the prePayload to be sent to our Servers
 * @return {JSX.Element} - returns filter button with input
 * @constructor
 */
const FilterInput = ({ id, name, value, handleInput, setData, tag }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleButtonClick = () => {
    if (name === "gsts") {
      if (isValidGstNo(value) === "false") {
        return enqueueSnackbar("Please enter valid GST Number", {
          variant: "error",
        });
      }
    }
    setData();
  };
  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button collapsed realtime__accordion-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={"#" + id}
        >
          <img
            src={`/assets/images/new_icons/${id}.png`}
            alt={tag}
            className="realtime__img"
          />
          {tag}
        </button>
      </h2>
      <div id={id} className="collapse" data-bs-parent="#accordionExample">
        <div className="pb-3 d-flex text-left flex-column align-items-center realtime__accordion-body">
          {id === "revenue" ? (
            <FilterDropdown collection={collection} handleInput={handleInput} />
          ) : (
            <input
              className="realtime_sme_input"
              type="text"
              name={id}
              onChange={handleInput}
              value={value}
              placeholder={tag}
              data-cy=""
            />
          )}
          {value ? (
            <button
              className="industry-btn"
              data-cy="sme-button"
              style={{
                width: "fit-content",
                margin: "10px auto 0 30px",
                cursor: "pointer",
              }}
              value={value}
              onClick={(e) => {
                handleButtonClick();
              }}
            >
              - {value}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Contains labels of filter inputs
 * @param prePayload
 * @param onClose - function that removes filters from the query container
 * @return {JSX.Element}
 * @constructor
 */
const QueryContainer = ({ prePayload, onClose }) => {
  const handleLabelClose = (key, item, value) => {
    if (Array.isArray(prePayload[key])) {
      value.splice(value.indexOf(item), 1);
      onClose((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      onClose((prev) => ({
        ...prev,
        [key]: "",
      }));
    }
  };
  return (
    <div className="px-4">
      <div className="query-parent">
        <div
          className="query-container"
          id="query_container"
          data-cy="query-container"
        >
          {Object.entries(prePayload).map(([key, value]) => {
            const iconUrl = `/assets/images/new_icons/${key}.png`;

            if (Array.isArray(value)) {
              return value.map((item, index) => (
                <FilterLabelInternal
                  key={`${key}-${index}`}
                  label={item}
                  iconUrl={iconUrl}
                  onCloseIconClicked={() => handleLabelClose(key, item, value)}
                />
              ));
            } else if (value !== "") {
              return (
                <FilterLabelInternal
                  key={key}
                  label={value}
                  iconUrl={iconUrl}
                  onCloseIconClicked={() => handleLabelClose(key, value)}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

const SmeFilters = ({ setLoading, setPageRecords, setCount }) => {
  const [isApply, setIsApply] = useState(false);
  const [inputData, setInputData] = useState({
    company_name: "",
    city: "",
    description: "",
    revenue: "",
    gsts: "",
  });
  const [prePayload, setprePayload] = useState({
    company_name: [],
    city: [],
    description: [],
    revenue: [],
    gsts: [],
  });

  const { enqueueSnackbar } = useSnackbar();
  const payload = Object.fromEntries(
    Object.entries(prePayload).filter(([key, value]) => value.length > 0)
  );
  const handleInputChange = (event, revenue) => {
    const { name, value } = event.target;
    if (revenue) {
      const revenueExists = Object.values(payload).some((array) =>
        array.includes(revenue)
      );
      if (!revenueExists) {
        setprePayload((prevData) => ({
          ...prevData,
          revenue: [...prevData.revenue, revenue],
        }));
      }
    }
    setInputData((prevData) => ({ ...prevData, [name]: value }));
  };

  const { data, status, refetch } = useQuery(
    ["SME Filtered Data", payload],
    isObjectValuesEmpty(payload)
      ? fetchSMEData
      : () => fetchSMEFilteredRecords(payload),
    { enabled: isApply }
  );
  const hasData = Object.values(payload).some((value) => value !== "");
  useEffect(() => {
    if (isApply) {
      refetch();
      setIsApply(!isApply);
    }
    if (status === "loading") {
      setLoading(true);
    }
    if (status === "success") {
      const filteredData = data?.["sme"].filter(
        (item) =>
          (item.company_name !== "" && item.contact_person !== "") ||
          item.state !== "" ||
          item.city !== ""
      );
      setPageRecords(filteredData);
      setCount(data?.count);
      setLoading(false);
    } else if (status === "error") {
      enqueueSnackbar(
        "Please try again after sometime. There was an error connecting to Leadzen Servers!",
        { variant: "error" }
      );
      setLoading(false);
    }
  }, [
    data,
    enqueueSnackbar,
    isApply,
    refetch,
    setPageRecords,
    setCount,
    status,
  ]);
  return (
    <div className="realtime__filter" data-cy="filter-search">
      <h3 className="realtime__heading">Filter Companies</h3>

      <QueryContainer prePayload={prePayload} onClose={setprePayload} />

      <div
        className="accordion accordion-flush"
        id="accordionExample"
        data-cy="filters"
      >
        <FilterInput
          id="company_name"
          name="company_name"
          tag="Company Name"
          value={inputData.company_name}
          setData={() => {
            const companyExists = Object.values(payload).some((array) =>
              array.includes(inputData.company_name)
            );
            if (!companyExists) {
              setprePayload((prev) => ({
                ...prev,
                company_name: [...prev.company_name, inputData.company_name],
              }));
              setInputData((prev) => ({ ...prev, company_name: "" }));
            }
          }}
          handleInput={handleInputChange}
        />
        <FilterInput
          id="city"
          name="city"
          tag="City"
          value={inputData.city}
          setData={() => {
            const cityExists = Object.values(payload).some((array) =>
              array.includes(inputData.city)
            );
            if (!cityExists) {
              setprePayload((prev) => ({
                ...prev,
                city: [...prev.city, inputData.city],
              }));
              setInputData((prev) => ({ ...prev, city: "" }));
            }
          }}
          handleInput={handleInputChange}
        />
        <FilterInput
          id="description"
          name="description"
          tag="Keywords"
          value={inputData.description}
          setData={() => {
            const descriptionExists = Object.values(payload).some((array) =>
              array.includes(inputData.description)
            );
            if (!descriptionExists) {
              setprePayload((prev) => ({
                ...prev,
                description: [...prev.description, inputData.description],
              }));
              setInputData((prev) => ({ ...prev, description: "" }));
            }
          }}
          handleInput={handleInputChange}
        />
        <FilterInput
          id="revenue"
          name="revenue"
          tag="Revenue"
          value={inputData.revenue}
          setData={() => {
            const revenueExists = Object.values(payload).some((array) =>
              array.includes(inputData.revenue)
            );
            if (!revenueExists) {
              setprePayload((prev) => ({
                ...prev,
                revenue: [...prev.revenue, inputData.revenue],
              }));
              setInputData((prev) => ({ ...prev, revenue: "" }));
            }
          }}
          handleInput={handleInputChange}
        />
        <FilterInput
          id="gsts"
          name="gsts"
          tag="GST Number"
          value={inputData.gsts}
          setData={() => {
            const gstsExists = Object.values(payload).some((array) =>
              array.includes(inputData.gsts)
            );
            if (!gstsExists) {
              setprePayload((prev) => ({
                ...prev,
                gsts: [...prev.gsts, inputData.gsts],
              }));
              setInputData((prev) => ({ ...prev, gsts: "" }));
            }
          }}
          handleInput={handleInputChange}
        />
      </div>
      <div className="realtime__search-box">
        <button
          type="button"
          className="realtime__search-btn"
          onClick={() => {
            if (!hasData) {
              setLoading(false);
              return enqueueSnackbar(
                "Please add atleast one search term in filters",
                { variant: "error" }
              );
            }
            setIsApply(!isApply);
          }}
        >
          {status === "loading" ? <Spinner color={"white"} /> : "Apply"}
        </button>
      </div>
    </div>
  );
};

export default SmeFilters;
