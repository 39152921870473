import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import {
  fetchBulkTaskStatus,
  fetchFileFromUrl,
  fetchHistory,
  fetchQueueTaskStatus,
  fetchSearchedHistory,
} from "./historyQueries";
import {
  callNTimes,
  cleanFileNameWithUUID,
  fancyTimeFormat,
  isJSON,
  Spinner,
  transformSmeData,
} from "../Utils/utils";
import Pagination from "../Utils/Pagination";
import ProgressBar from "react-bootstrap/ProgressBar";
import excelLogo from "../../images/excellogo.png";
import download from "downloadjs";
import { AppContext } from "../../Context";
import "./history.css";
import dayjs from "dayjs";
import { apiServer } from "../../config";

const itemsPerPage = 10;

const QueueStatus = ({ search_id, setETA }) => {
  const { data, status } = useQuery(
    ["Queue Task Status", search_id],
    () => fetchQueueTaskStatus(search_id),
    {
      refetchInterval: 300 * 1000,
    }
  );

  useEffect(() => {
    console.log(data);

    if (status === "success") setETA(data?.["waiting_time"]);
  }, [data, status, setETA]);

  return <>{status === "success" && <>Queue: {data?.["queue_position"]}</>}</>;
};

const BulkTaskStatus = ({
  search_id,
  file_name,
  setDisabledButton,
  setETA,
}) => {
  const [fetchInterval, setFetchInterval] = useState(10 * 1000);
  const payload = {
    search_id: search_id,
    file_name: file_name,
  };

  const { data, status } = useQuery(
    ["Bulk Task Status", payload],
    () => fetchBulkTaskStatus(payload),
    {
      refetchInterval: fetchInterval,
    }
  );

  useEffect(() => {
    console.log(data);

    if (data?.status === "success") {
      setDisabledButton(false);
      setFetchInterval(false);
      setETA(false);
    }

    if (data?.status === "running") {
      const currTime = data?.["estimated_time"];
      if (currTime) {
        callNTimes((num) => setETA(currTime + num - 10), 10, 1000);
      }
    }
  }, [data, setDisabledButton, setETA]);

  return (
    <>
      {status === "success" && (
        <>
          {data?.progress > 0 && <ProgressBar now={data?.progress} />}
          {data?.status === "running" && "In Progress"}
          {data?.status === "success" && "Download"}
        </>
      )}
    </>
  );
};

const HistoryButton = ({ record, child }) => {
  const [disabledButton, setDisabledButton] = useState(false);
  const [estimatedTime, setEstimatedTime] = useState(false);

  // Destructuring record object
  const { name, search_term, search_id, search_results, task_status } = record;
  const initialStatus = task_status?.status;

  // Using Reducer
  const history = useHistory();
  const { dispatch } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Disable button for these statuses
    if (
      initialStatus === "queued" ||
      initialStatus === "failed" ||
      initialStatus === "running"
    ) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [initialStatus]);

  async function fetchSearchResult(dataId, child) {
    try {
      console.log("child in fetch result: ", child);
      const url = child
        ? `${apiServer}/history/get_search_result/${dataId}?child=${child}`
        : `${apiServer}/history/get_search_result/${dataId}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const res = await response?.json();
      console.log("res: ", res);
      return res;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error; // Re-throw the error to handle it outside
    }
  }

  const handleProfile = async (e, data, child) => {
    if (data.search_type === "people") {
      history.push({
        pathname: "/simple_history_all", // BasicSearchResult
        state: { details: data },
      });
    }
    if (data.search_type === "Extension-company") {
      const search_data = await fetchSearchResult(data._id, child);
      history.push({
        pathname: "/extensionCompany", // BasicSearchResult
        state: { details: search_data["result"][0] },
      });
    }
    if (data.search_type === "company") {
      const search_data = await fetchSearchResult(data._id, child);

      history.push({
        pathname: "/CompanySearchHistory", // CompanySearch
        state: { details: search_data["result"][0] },
      });
    }
    if (data.search_type === "company_filter") {
      const search_data = await fetchSearchResult(data._id, child);
      history.push({
        pathname: "/CompanySearchHistory", // CompanySearch
        state: { details: search_data["result"][0] },
      });
    }

    if (data.search_type === "realTimeCompany") {
      // SearchResultCompany
      history.push({
        pathname: "/result_by_history_type4",
        state: { details: data, child },
      });
    }
    if (data.search_type === "realtime-sme-filter") {
      const search_data = await fetchSearchResult(data._id, child);
      // SmeListView
      console.log("search_data", search_data?.result);
      history.push({
        pathname: "/smeCompanies",
        state: { details: search_data["result"] },
      });
    }
    if (data.search_type === "map") {
      history.push({
        pathname: "/geolocator_history", // BasicSearchResult
        state: { details: data },
      });
    }
    if (data.search_type.includes("realtime.filter")) {
      console.log("In realtime record: ", data);
      const search_data = await fetchSearchResult(data._id, child);
      history.push({
        pathname: "/realtime_history", // BasicSearchResult
        state: { details: search_data["result"][0] },
      });
    }
    if (data.search_type === "realtime.people.filter") {
      console.log("data: ", data);
      let json_payload = JSON.parse(data.search_term);
      let payload = {
        sheetName: json_payload.index,
        payload: json_payload,
      };
      console.log("payload: ", payload);
      history.push({
        pathname: "/people/realtime/filter", // BasicSearchResult
        state: payload,
      });
    }
    // for realtime people
    if (data.search_type === "realtime.people.unlock") {
      history.push({
        pathname: "/simple_history_all", // BasicSearchResult
        state: { details: data },
      });
    }
    if (data.search_type === "sme_unlock") {
      const search_data = await fetchSearchResult(data._id, child);
      history.push({
        pathname: "/smeCompanies",
        state: { details: transformSmeData(search_data["result"][0]) },
      });
    }
    if (
      data.search_type === "bulk" ||
      data.search_type === "export" ||
      data.search_type === "map_export" ||
      data.search_type === "realtime_export" ||
      data.search_type === "custom_bulk"
    ) {
      const filePath =
        data?.search_results?.[0]?.["file_name"] ||
        `shared/bulk/outgoing/${search_term}`;

      const fileName = filePath.split("/").at(-1);

      const url = `/${filePath.replace('"', "").replace('"', "")}`;

      try {
        const fileBlob = await fetchFileFromUrl(url);

        download(fileBlob, fileName, fileBlob?.type);
      } catch (e) {
        enqueueSnackbar("Error while downloading your file!", {
          variant: "error",
        });
      }
    }
  };

  return (
    <div style={{ flexBasis: "20%" }}>
      <p className="view-btn" align="center">
        <button
          className="button btn"
          style={{ color: "black", width: "65%" }}
          onClick={async (e) => {
            await handleProfile(e, record, child);
            dispatch({ type: "upsetDemo" });
            dispatch({
              type: "setHistorySearchTerm",
              payload: search_term !== '""' ? isJSON(search_term) : name,
            });
          }}
          disabled={disabledButton}
        >
          {initialStatus === "success" ? (
            "Download"
          ) : initialStatus === "failed" ? (
            "Failed"
          ) : initialStatus === "queued" ? (
            <QueueStatus search_id={search_id} setETA={setEstimatedTime} />
          ) : initialStatus === "running" ? (
            <BulkTaskStatus
              search_id={search_id}
              file_name={search_results?.[0]["file_name"]}
              setDisabledButton={setDisabledButton}
              setETA={setEstimatedTime}
            />
          ) : (
            "View Result"
          )}
        </button>
      </p>

      {estimatedTime &&
        initialStatus !== "success" &&
        initialStatus !== "failed" && (
          <div>ETA: {fancyTimeFormat(estimatedTime)}</div>
        )}
    </div>
  );
};

const HistoryListView = ({
  onRepeatedPage = false,
  type,
  search_term,
  child,
  toggle,
}) => {
  const [pageRecords, setPageRecords] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  const { data, status, refetch } = useQuery(
    ["People History", page, type, search_term],
    type === "search"
      ? () =>
          fetchSearchedHistory({
            search_term: search_term,
            page: page,
            child: child ? encodeURIComponent(child) : null,
            toggle: toggle,
          })
      : () => fetchHistory(page, type, child),
    {
      refetchOnMount: "true",
      staleTime: 10 * 1000,
    }
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      setPageRecords([]);
      await refetch();
      setPage(0);
    })();
  }, [refetch, type]);

  useEffect(() => {
    setPageRecords([]);
    if (status === "error") {
      enqueueSnackbar(
        "Please try again after sometime. We are working on fetching your history information.",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    }
    setPageRecords(data?.history);
    console.log("data.history: ", data?.history);
    setTotalCount(data?.count);
  }, [status, data, enqueueSnackbar]);
  console.log("pageRecords", pageRecords);
  return (
    <>
      <h5 className="text-start mt-2">No. of Records: {totalCount}</h5>

      {pageRecords?.length > 0 ? (
        <div>
          {pageRecords.map((item, index) => {
            // Destructuring history record
            const { search_type, search_term } = item;
            const convertUTCToLocal = (date) => {
              return new Date(
                date.getTime() - date.getTimezoneOffset() * 60 * 1000
              );
            };
            const date = convertUTCToLocal(new Date(item?.["created_on"]));
            const time = new Date(date).toLocaleTimeString("en-US");
            return (
              <div className="container-style mt-3 global-shadow" key={index}>
                <div
                  className="history-container"
                  data-cy="history-list-container"
                >
                  <div style={{ flexBasis: "30%" }}>
                    {search_type === "bulk" ||
                    search_type === "export" ||
                    search_type === "map_export" ? (
                      search_term.includes(".csv") ||
                      search_term.includes(".xlsx") ? (
                        <div className="history-list-item">
                          <p className="text-danger">Profile:</p>
                          <p>{search_term}</p>

                          <img
                            className="excel-icon"
                            src={excelLogo}
                            alt="Excel-logo"
                          />
                        </div>
                      ) : (
                        <div className="history-list-item">
                          <p className="text-danger">Profile:</p>
                          <p>{search_type}</p>
                          <img
                            className="history-excel-logo"
                            src={excelLogo}
                            alt="Excel-logo"
                          />
                        </div>
                      )
                    ) : search_term === '""' ? (
                      <div className="history-list-item">
                        <p className="text-danger">Profile:</p>
                        <p className="history-break">
                          {item.search_results &&
                          item.search_results[0] &&
                          item.search_results[0].name
                            ? item.search_results[0].name
                            : "Not Available"}
                        </p>
                      </div>
                    ) : (
                      <div className="history-list-item">
                        <p className="text-danger">Profile:</p>
                        <p className="text-capitalize profile-text">
                          {cleanFileNameWithUUID(isJSON(search_term))}
                          {/*{isJSON(search_term)}*/}
                          {search_type === "custom_bulk" && (
                            <p>Delivered by: {item["delivered_by"]}</p>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    className="history-search-date"
                    style={{
                      flexDirection: onRepeatedPage ? "column" : "",
                      gap: !onRepeatedPage ? "1rem" : "",
                    }}
                  >
                    <p className="text-danger">Search Date:</p>
                    <div>
                      <p>{dayjs(item?.["created_on"]).format("DD MMM YYYY")}</p>
                      <p>{search_type !== "custom_bulk" && time}</p>
                    </div>
                  </div>
                  <div
                    className="history-search-date"
                    style={{
                      flexDirection: onRepeatedPage ? "column" : "",
                      gap: !onRepeatedPage ? "1rem" : "",
                    }}
                  >
                    <p className="text-danger">Credits used:</p>

                    {search_type === "realtime-sme-filter" ||
                    search_type === "sme_unlock" ? (
                      <>
                        <div
                          className={`${
                            onRepeatedPage ? "min-width-on-repeated" : ""
                          }`}
                        >
                          <p>{item["profile_count"]}</p>
                          {/* <p>Mail: {item["email_count"]}</p> */}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={`${
                            onRepeatedPage ? "min-width-on-repeated" : ""
                          }`}
                        >
                          <p>Profile: {item["profile_count"]}</p>
                          <p>Mail: {item["email_count"]}</p>
                        </div>
                      </>
                    )}
                  </div>
                  <HistoryButton record={item} child={child} />
                </div>
              </div>
            );
          })}
          <Pagination
            onPageChange={({ selected }) => setPage(selected)}
            pageNumber={page}
            pageCount={Math.ceil(totalCount / itemsPerPage)}
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center mt-2">
          {status === "loading" ? (
            <Spinner />
          ) : (
            <h5 className="my-5">No records found</h5>
          )}
        </div>
      )}
    </>
  );
};

export default HistoryListView;
