import React from "react";

const RealtimeSelectItem = ({
  realtimeSelectedLeads,
  setRealtimeSelectedLeads,
  companyLinkedIn,
  companyName,
  companyContactLinkedIn,
  companyContactName,
  index,
  sheetName,
  pathName,
  setCustomCount,
  customCount,
  companyPageRecord,
}) => {
  // console.log( realtimeSelectedLeads?.filter(
  //   (item) => item.company_name === "IBM" || item.location !== "Armonk, NY"
  // ))
  const isCompanySelected = () => {
    if (
      sheetName === "realtime-companies_hiring_recently" &&
      (pathName === "/realtime/filter" || pathName === "/realtime_history")
    ) {
      companyName = companyPageRecord.company_name;
      const companyLocation = companyPageRecord.location;
      const jobTitle = companyPageRecord.title;
      return realtimeSelectedLeads?.some(
        (member) =>
          member?.company_name === companyName &&
          member.location === companyLocation &&
          member.title === jobTitle
      );
    } else {
      if (sheetName === "public_listed_companies") {
        return realtimeSelectedLeads?.some(
          (member) => member?.company_name === companyName
        );
      } else {
        return realtimeSelectedLeads?.some(
          (member) =>
            member?.company_linkedin_url === companyLinkedIn &&
            member?.company_name === companyName &&
            member?.contact_person_linkedin === companyContactLinkedIn &&
            member?.contact_person_name === companyContactName
        );
      }
    }
  };

  const handleLeadSelectionChange = (e) => {
    const { checked } = e.target;

    if (!checked) {
      if (
        sheetName === "realtime-companies_hiring_recently" &&
        (pathName === "/realtime/filter" || pathName === "/realtime_history")
      ) {
        companyName = companyPageRecord.company_name;
        const companyLocation = companyPageRecord.location;
        const jobTitle = companyPageRecord.title;
        setRealtimeSelectedLeads(
          realtimeSelectedLeads?.filter(
            (item) =>
              item.location !== companyLocation && item.title !== jobTitle
          )
        );
      } else {
        if (sheetName === "realtime-public_listed_companies") {
          setRealtimeSelectedLeads(
            realtimeSelectedLeads?.filter(
              (item) => item.company_name !== companyName
            )
          );
        } else {
          setRealtimeSelectedLeads(
            realtimeSelectedLeads?.filter(
              (item) =>
                item.company_linkedin_url !== companyLinkedIn &&
                item.company_name !== companyName
            )
          );
        }
      }
      setCustomCount(customCount - 1);
    } else {
      if (
        sheetName === "realtime-companies_hiring_recently" &&
        (pathName === "/realtime/filter" || pathName === "/realtime_history")
      ) {
        if (!isCompanySelected()) {
          companyName = companyPageRecord.company_name;
          const companyLocation = companyPageRecord.location;
          const jobTitle = companyPageRecord.title;
          setRealtimeSelectedLeads([
            ...realtimeSelectedLeads,
            {
              company_name: companyName,
              location: companyLocation,
              title: jobTitle,
            },
          ]);
          setCustomCount(customCount + 1);
        }
      } else {
        if (!isCompanySelected()) {
          setRealtimeSelectedLeads([
            ...realtimeSelectedLeads,
            {
              company_linkedin_url: companyLinkedIn,
              company_name: companyName,
              contact_person_linkedin: companyContactLinkedIn,
              contact_person_name: companyContactName,
            },
          ]);
          setCustomCount(customCount + 1);
        }
      }
    }
  };

  return (
    <>
      <input
        className="box"
        id={index}
        type="checkbox"
        name="name"
        checked={isCompanySelected()}
        onChange={(e) => handleLeadSelectionChange(e)}
      />
    </>
  );
};

export default RealtimeSelectItem;
