import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { motion } from "framer-motion";
import { fetchSMEUnlockedRecord } from "./SmeQueries";
import { Spinner } from "../../Utils/utils";
import { slideInSlideOut } from "../../../Config/motions";

const cleanDescription = (desc) => {
  // Remove HTML tags
  const withoutHtmlTags = desc?.replace(/<\/?[^>]+(>|$)/g, "");
  // Remove special characters like \r\n\r\
  const withoutSpecialChars = withoutHtmlTags?.replace(/\\r\\n|\\r|\\n/g, " ");
  return desc ? withoutSpecialChars : "";
};

const SmeDetailedView = ({ lead }) => {
  /**
   * Generates a column of title and values dynamically
   * Adjust parameters inputs to update the layout of lead
   * @param parameters
   * @return {JSX.Element}
   * @constructor
   */
  console.log("in detailed view lead: ", lead);
  let innerData;
  if (lead?.length > 0) innerData = lead[0];
  else innerData = lead;
  console.log("inner data:", innerData);
  const shouldRender = (value) => {
    return value && value[0].trim() !== ""; // Check for non-empty and non-whitespace value
  };

  return (
    <div className="modal-content2" style={{ width: "95%", margin: "0 auto" }}>
      <section
        className="item-section mt-3"
        style={{ textAlign: "initial" }}
        data-cy="sme-profile-container"
      >
        <div className="d-flex phone-child-div  gap-8">
          <div className="flex-column">
            {shouldRender(innerData?.company_name) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">Company Name: </p>
                <p className="fs-6">{innerData.company_name[0]}</p>
              </div>
            )}
            {shouldRender(innerData?.city) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">City: </p>
                <p className="fs-6">{innerData.city[0]}</p>
              </div>
            )}
            {shouldRender(innerData?.state) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">State: </p>
                <p className="fs-6">{innerData.state[0]}</p>
              </div>
            )}
            {shouldRender(innerData?.country) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">Country: </p>
                <p className="fs-6">{innerData.country[0]}</p>
              </div>
            )}
            {shouldRender(innerData?.address) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">Address: </p>
                <p className="fs-6">{innerData.address[0]}</p>
              </div>
            )}
            {shouldRender(innerData?.contact_person) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">Contact Person: </p>
                <p className="fs-6">{innerData.contact_person[0]} </p>
              </div>
            )}
            {shouldRender(innerData?.emails) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">Emails: </p>
                <p className="fs-6">{innerData?.emails[0]}</p>
              </div>
            )}
            {shouldRender(innerData?.phones) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">Phone Numbers: </p>
                <p>
                  {innerData.phones &&
                    innerData.phones[0]
                      .split(",")
                      .map((phone, index) => <p key={index}> {phone}</p>)}
                </p>
              </div>
            )}
          </div>
          <div className="flex-column">
            {shouldRender(innerData?.description) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">Description:</p>
                <p className="fs-6">
                  {" "}
                  {cleanDescription(innerData?.description[0])}
                </p>
              </div>
            )}
            {shouldRender(innerData?.category) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">Category: </p>
                <p className="fs-6">{innerData.category[0]} </p>
              </div>
            )}
            {shouldRender(innerData?.no_of_employees) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">No of employess: </p>
                <p className="fs-6">{innerData.no_of_employees[0]}</p>
              </div>
            )}
            {shouldRender(innerData?.industry) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">Industry: </p>
                <p className="fs-6">{innerData.industry[0]}</p>
              </div>
            )}
            {shouldRender(innerData?.gsts) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">GST Number: </p>
                <p className="fs-6">{innerData.gsts[0]}</p>
              </div>
            )}
            {shouldRender(innerData?.turnover_range) && (
              <div className={"d-flex flex-column"}>
                <p className="fw-bold fs-5">Turnover:</p>
                <p className="fs-6"> {innerData.turnover_range[0]}</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

const SmeLockedInfo = ({ page, lead, selected, setSelected, allSelected }) => {
  const [unlock, setUnlock] = useState(false);
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const payload = lead["uuid_"];
  const search_id_payload = lead["search_id"];
  const { data, status, error, refetch } = useQuery(
    ["SME Unlocked Information", payload],
    () => fetchSMEUnlockedRecord(payload, search_id_payload),
    { enabled: unlock }
  );
  console.log("after unlock: ", data, status);
  useEffect(() => {
    if (error?.message) {
      enqueueSnackbar(error?.message, { variant: "error" });

      setUnlock(false);
    } else if (status === "error") {
      enqueueSnackbar(
        "Please try again after sometime. There was an error accessing information!",
        { variant: "error" }
      );

      setUnlock(false);
    }
    if (unlock) {
      refetch();
    }
  }, [enqueueSnackbar, error?.message, refetch, status, unlock]);

  const handleLeadSelectionChange = (e) => {
    const { id, checked } = e.target;

    setSelected([...selected, id]);

    if (!checked) {
      setSelected(selected.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    setUnlock(false);
  }, [page]);

  let searchResults;
  console.log("data.sme: ", data?.["sme"], data?.["sme"]?.search_results);
  if (data?.["sme"]?.search_results) {
    try {
      searchResults = data?.["sme"]?.search_results;
    } catch (error) {
      console.error("Error parsing search_results:", error);
      // }
    }
  } else {
    searchResults = data?.["sme"];
  }
  console.log("search result: ", searchResults);
  return (
    <div
      onMouseOver={() => setOpen(true)}
      onMouseOut={() => setOpen(false)}
      className="main"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "8rem",
          padding: "1rem",
          flex: "1 1 auto",
        }}
        data-cy="search-results"
      >
        <input
          id={lead["uuid_"]}
          type="checkbox"
          // disabled={props.isExport}
          // checked={selected.includes(lead["uuid_"]) || allSelected}
          disabled
          onChange={(e) => handleLeadSelectionChange(e)}
          data-cy="sme-list-checkbox"
          hidden={true}
        />
        <div style={{ flexBasis: "15%" }}>
          <h6 style={{ fontWeight: "bold", flexBasis: "25%" }}>
            {lead.company_name ? lead.company_name.replace(/[0-9()]/g, '') : "-"}
          </h6>
        </div>

        <div style={{ flexBasis: "15%" }}>
          <p className="bold_header">
            <b>CONTACT</b>
          </p>
          <h6 style={{ fontWeight: "normal" }}>
            {lead["contact_person"] ? lead["contact_person"] : "-"}
          </h6>
        </div>

        <div style={{ flexBasis: "15%" }}>
          <p className="bold_header">
            <b>CITY</b>
          </p>
          <h6 style={{ fontWeight: "normal" }}>
            {lead.city ? lead.city : "-"}
          </h6>
        </div>

        <div style={{ flexBasis: "15%" }}>
          <p className="bold_header">
            <b>STATE</b>
          </p>
          <h6 style={{ fontWeight: "normal" }}>
            {lead.state ? lead.state : "-"}
          </h6>
        </div>

        <button
          className="btn button v-btn"
          style={{ flexBasis: "10%" }}
          type="button"
          onClick={() => setUnlock(!unlock)}
          data-cy="unlock-profile-btn"
        >
          {unlock ? "Hide Details" : "Unlock Details"}
        </button>
      </div>

      {lead?.description && open && (
        <motion.div className="mx-3 " {...slideInSlideOut}>
          {cleanDescription(lead?.description)}
        </motion.div>
      )}

      {status === "loading" && unlock && <Spinner />}
      {data && unlock && <SmeDetailedView lead={searchResults} />}
    </div>
  );
};

export default SmeLockedInfo;
