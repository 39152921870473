//Components
import Mapbox from "./mapbox/Mapbox";
import CategorySelect from "./categorySelect/CategorySelect";
import DisplaySearchArea from "./displaySearchArea/DisplaySearchArea";
import ViewDetails from "./viewDetails/ViewDetails";
import LeadsData from "./leadsData/LeadsData";
//REDUX
import {
  saveCategorySelected,
  saveCategorySelectedArray,
  saveClearSearchResult,
  selectViewDetail,
} from "../../_reducer/shared";
import { useDispatch, useSelector } from "react-redux";
//CSS
import "./Geolocator.css";
import { useEffect, useState } from "react";
import { saveMapBoxAutocomplete, selectMapBoxAutocomplete } from "../../_reducer/areaDetail";
import { saveLeadsData, saveLeadsForMapMarker } from "../../_reducer/leads";

function Geolocator() {
  const dispatch = useDispatch();
  const [simpleSearchId, setSimpleSearchId] = useState(null);
  const [center, setCenter] = useState({});
  const [category, setCategory] = useState("");
  const [allCoordinates, setAllCoordinates] = useState([]);
  const [mapSelectedLeads, setMapSelectedLeads] = useState([]);
  const [oldBbox, setOldBbox] = useState()
  const mapBoxAutocomplete = useSelector(selectMapBoxAutocomplete);


  useEffect(() => {
    return () => {
      dispatch(saveMapBoxAutocomplete({}));
      dispatch(saveCategorySelected(null));
      dispatch(saveCategorySelectedArray([]));
      dispatch(saveClearSearchResult(true));
      dispatch(saveLeadsData({}));
      dispatch(saveLeadsForMapMarker([]));
      setCategory(null);
    };
  }, []);

  return (
    <>
      {/*  <Topbar /> */}

      {/* <Header user={user} /> */}

      {/*{!viewDetail?.view ? (*/}
      <div>
        <div className="mapbox_mainContainer">
          <Mapbox
            setCenter={setCenter}
            setCategory={setCategory}
            setAllCoordinates={setAllCoordinates}
          />
          <CategorySelect
            setSimpleSearchId={setSimpleSearchId}
            center={center}
            category={category}
            setCategory={setCategory}
            allCoordinates={allCoordinates}
            mapSelectedLeads={mapSelectedLeads}
            setMapSelectedLeads={setMapSelectedLeads}
          />
          {/*Hide Search area details */}
          {/*<DisplaySearchArea setCategory={setCategory} />*/}
        </div>
        <LeadsData
          setSimpleSearchId={setSimpleSearchId}
          simpleSearchId={simpleSearchId}
          center={center}
          setCategory={setCategory}
          allCoordinates={allCoordinates}
          mapSelectedLeads={mapSelectedLeads}
          setMapSelectedLeads={setMapSelectedLeads}
        />
      </div>
      {/*) : (*/}
      {/*  // <ViewDetails simpleSearchId={simpleSearchId} />*/}
      {/*)}*/}
    </>
  );
}

export default Geolocator;
