import React from "react";
import RealtimeSelectAllExport from "./RealtimeSelectAllExport";
import RealTimeExportResult from "./RealTimeExportResult";
import { useSnackbar } from "notistack";
import {
  setCompanyName,
  setContactPersonLinkedin,
  setLinkedIn,
  setContactPersonName,
} from "./util";

const RealtimeExportAll = ({
  leads,
  realtimeSelectedLeads,
  setRealtimeSelectedLeads,
  companyPageRecords,
  allCompanyPageRecords,
  totalCount,
  sheetName,
  pathName,
  customCount,
  setCustomCount,
  isRealtimeCheckAll,
  setIsRealtimeCheckAll,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSearch = async (e) => {
    console.log("e in select export count..: ", e.target.value === "");
    if (e.target.value === "") {
      setCustomCount(0);
      setRealtimeSelectedLeads([]);
      setIsRealtimeCheckAll(false);
      return;
    }
    if (e.target.value > totalCount) {
      enqueueSnackbar("Invalid Lead Selection Number!", {
        variant: "error",
      });
      setCustomCount(totalCount);
      return;
    }

    setCustomCount(e.target.value);

    let updatedArray = allCompanyPageRecords?.map((item) => ({
      company_linkedin_url: setLinkedIn(sheetName, item),
      company_name: setCompanyName(sheetName, item),
      contact_person_linkedin: setContactPersonLinkedin(sheetName, item),
      contact_person_name: setContactPersonName(sheetName, item),
    }));
    setRealtimeSelectedLeads(
      updatedArray && Array.isArray(updatedArray)
        ? updatedArray.slice(0, e.target.value)
        : []
    );
  };

  return (
    <>
      <div className="user-widget-box  my-3">
        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center ">
            <RealtimeSelectAllExport
              leads={leads}
              setRealtimeSelectedLeads={setRealtimeSelectedLeads}
              sheetName={sheetName}
              setCustomCount={setCustomCount}
              isRealtimeCheckAll={isRealtimeCheckAll}
              setIsRealtimeCheckAll={setIsRealtimeCheckAll}
              pathName={pathName}
              companyPageRecords={companyPageRecords}
              allCompanyPageRecords={allCompanyPageRecords}
            />
            <small className="">
              <input
                type="number"
                value={customCount}
                size="0"
                onChange={(e) => setCustomCount(e.target.value)}
                onInput={(e) => setCustomCount(e.target.value)}
                onBlur={handleSearch}
                disabled={!isRealtimeCheckAll}
                min="1"
                max={totalCount}
              />
              <b> {realtimeSelectedLeads?.length}</b> of {leads?.length}{" "}
              Profiles
            </small>
          </div>

          <div className="d-flex align-items-center">
            <RealTimeExportResult
              realtimeSelectedLeads={realtimeSelectedLeads}
              sheetName={sheetName}
              pathName={pathName}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default RealtimeExportAll;
